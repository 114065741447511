var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "coummunity" }, [
    _c(
      "div",
      { staticClass: "main flex-layout" },
      [_vm._m(0), _c("router-view")],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "sidebar" }, [
      _c("div", [
        _c("div", { staticClass: "sidebar-title" }, [_vm._v("社区")]),
        _c("ul", { staticClass: "sidebar-list" }, [
          _c("li", { staticClass: "sidebar-list-item" }, [_vm._v("发现")]),
          _c("li", { staticClass: "sidebar-list-item" }, [_vm._v("悬赏")]),
          _c("li", { staticClass: "sidebar-list-item active" }, [
            _vm._v("我的"),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "sidebar-ad" }, [_vm._v("广告位")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }