<template>
  <!-- 社区主页 -->
  <div class="coummunity">
    <div class="main flex-layout">
      <div class="sidebar">
        <div>
          <div class="sidebar-title">社区</div>
          <ul class="sidebar-list">
            <li class="sidebar-list-item">发现</li>
            <li class="sidebar-list-item">悬赏</li>
            <li class="sidebar-list-item active">我的</li>
          </ul>
        </div>
        <div class="sidebar-ad">广告位</div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.coummunity {
  height: calc(100vh - 73px);
  background: #f2f2f2;

  .main {
    margin: 0 auto;
    width: 1200px;
    height: 100%;
    padding-top: 13px;
    padding-bottom: 30px;
    box-sizing: border-box;
  }

  .sidebar {
    margin-right: 14px;
    border-radius: 10px;
    box-shadow: 0px 0px 8px 0px rgba(100, 110, 144, 0.1);
    width: 200px;
    height: 80vh;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0;

    .sidebar-title {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      padding: 36px 24px;
      background-color: #fafafa;
    }

    .sidebar-list {
      .sidebar-list-item {
        font-size: 16px;
        font-weight: 600;
        line-height: 52px;
        padding: 0px 24px;
        position: relative;
        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          left: 0;
          width: 3px;
          height: 52px;
          background-color: #01aded;
          display: none;
        }


        &:hover,
        &.active {
          background-color: #f1faff;
          &::before {
            display: block;
          }
        }
      }
    }

    .sidebar-ad {
      height: 300px;
      background-color: #f1faff;
      text-align: center;
      line-height: 300px;
    }
  }
}
</style>
